<template>
    <div class>
        <div class="flex items-center title font-bold">
            <div class="text-left text-gray-500 cursor-pointer" @click="goback">&lt;返回</div>
            <div class="text-left ml-4">{{ info[pageType].title }}</div>
        </div>
        <div>
            <div
                class="mt-8 flex sm:justify-center equipment duration-500"
                v-if="pageType == 'password'"
            >
                <el-form ref="form" label-width="90px" label-position="center">
                    <el-form-item label="旧密码" class="flex justify-start">
                        <el-input
                            v-model="oldPassWord"
                            placeholder="请输入旧密码"
                            class="form_input duration-500"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="新密码" class="flex justify-start">
                        <el-input
                            v-model="newPassWord"
                            placeholder="请输入新密码(密码为8-16个字符)"
                            class="form_input duration-500"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="密保问题" class="flex justify-start">
                        <el-input
                            v-model="secretAnswer"
                            placeholder="请输入密保问题答案"
                            class="form_input duration-500"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="." class="flex justify-start btn_item">
                        <el-button type="primary" class="btn duration-500" @click="checkRules">确定</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div
                class="mt-8 flex sm:justify-center equipment duration-500"
                v-if="pageType == 'email'"
            >
                <el-form ref="form" label-width="90px" label-position="center">
                    <el-form-item label="邮箱" class="flex justify-start">
                        <el-input
                            v-model="email"
                            placeholder="请输入邮箱"
                            class="form_input duration-500"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="." class="flex justify-start btn_item">
                        <el-button type="primary" class="btn duration-500" @click="checkRules">确定</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div
                class="mt-8 flex sm:justify-center equipment duration-500"
                v-if="pageType == 'phone'"
            >
                <el-form ref="form" label-width="90px" label-position="center">
                    <el-form-item label="请输入手机" class="flex justify-start">
                        <el-input
                            v-model="phone"
                            placeholder="请输入手机号码"
                            class="form_input duration-500"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="." class="flex justify-start btn_item">
                        <el-button type="primary" class="btn duration-500" @click="checkRules">确定</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            info: {
                password: {
                    title: "保护密码"
                },
                email: {
                    title: "安全邮箱"
                },
                phone: {
                    title: "安全手机"
                }
            },
            pageType: "password",
            oldPassWord: "",
            newPassWord: "",
            secretAnswer: "",
            email: "",
            phone: "",
        };
    },
    created() {
        this.pageType = this.$route.query.type
    },
    methods: {
        goback() {
            this.$router.go(-1);//返回上一层
        },
        // 输入校验
        checkRules() {
            if (this.pageType == 'password') {
                if (this.isNull(this.oldPassWord)) {
                    this.$EventBus.$emit("toast", { type: 'error', message: '旧密码不能为空' })
                    return
                }
                if (!this.checkPwd(this.newPassWord)) {
                    this.$EventBus.$emit("toast", { type: 'error', message: '密码不符合规则，请重新设置' })
                    return
                }
                if (this.isNull(this.secretAnswer)) {
                    this.$EventBus.$emit("toast", { type: 'error', message: '密保问题答案不能为空' })
                    return
                }
            }
            if (this.pageType == "email") {
                if (!this.checkEmail(this.email)) {
                    this.$EventBus.$emit("toast", { type: 'error', message: '邮箱错误' })
                    return
                }
            }
            if (this.pageType == 'phone') {
                if (!this.checkPhone(this.phone)) {
                    this.$EventBus.$emit("toast", { type: 'error', message: '手机号码错误' })
                    return
                }
            }
            this.submit()
        },
        submit() {
            let url = ""
            let data = {}
            let msg = ""
            switch (this.pageType) {
                case "password":
                    url = this.$requestPath.updPassword
                    data = {
                        "newPassWord": this.newPassWord,
                        "oldPassWord": this.oldPassWord,
                        "secretAnswer": this.secretAnswer
                    }
                    msg = "修改密码"
                    break;
                case "phone":
                    url = this.$requestPath.bindPhone
                    data = {
                        phone: this.phone
                    }
                    msg = "绑定手机"
                    break
                case "email":
                    url = this.$requestPath.bindEmail
                    data = {
                        email: this.email
                    }
                    msg = "绑定邮箱"
                    break
            }
            this.$request({
                method: "post",
                url,
                data
            }).then(res => {
                console.log(msg, res)
                if (res.code == 0) {
                    setTimeout(() => {
                        switch (this.pageType) {
                            case "password":
                                this.newPassWord = ""
                                this.oldPassWord = ""
                                this.secretAnswer = ""
                                this.$EventBus.$emit("toast", { type: 'success', message: msg + '成功,请重新登录' })
                                setTimeout(() => {
                                    this.$store.commit("updateLoginStatus", false)
                                }, 1000)
                                break;
                            case "phone":
                                this.phone = ""
                                this.$EventBus.$emit("toast", { type: 'success', message: msg + '成功' })
                                break
                            case "email":
                                this.email = ""
                                this.$EventBus.$emit("toast", { type: 'success', message: msg + '成功' })
                                break
                        }
                    }, 1000)
                } else {
                    this.$EventBus.$emit("toast", { type: 'error', message: res.msg })
                }
            }).catch(error => {
                console.log(msg + '错误', error)
            })

        },
        // 校验密码
        checkPwd(pwd) {
            var reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/
            var re = new RegExp(reg)
            if (re.test(pwd)) {
                return true;
            } else {
                return false;
            }
        },
        // 校验电子邮件
        checkEmail(email) {
            var reg = new RegExp("^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"); //正则表达式
            return reg.test(email)
        }
    },
};
</script>

<style lang='scss' scoped>
@media screen and (max-width: 680px) {
    .title {
        font-size: 14px;
    }
    .form_input {
        width: 15.625rem;
    }
    .btn {
        width: 6.25rem;
    }
}
@media screen and (min-width: 680px) {
    .title {
        font-size: 16px;
    }
    .form_input {
        width: 18.75rem;
    }
    .btn {
        width: 12.5rem;
    }
}
</style>
<style>
.btn_item label {
    opacity: 0;
}
.equipment .el-form-item__content {
    margin-left: 10px !important;
    width: calc(100% - 90px);
}
</style>